exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-cloud-infrastructure-tsx": () => import("./../../../src/pages/cloud-infrastructure.tsx" /* webpackChunkName: "component---src-pages-cloud-infrastructure-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-data-confluence-tsx": () => import("./../../../src/pages/data-confluence.tsx" /* webpackChunkName: "component---src-pages-data-confluence-tsx" */),
  "component---src-pages-data-engineering-tsx": () => import("./../../../src/pages/data-engineering.tsx" /* webpackChunkName: "component---src-pages-data-engineering-tsx" */),
  "component---src-pages-identity-confluence-tsx": () => import("./../../../src/pages/identity-confluence.tsx" /* webpackChunkName: "component---src-pages-identity-confluence-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integration-connectors-tsx": () => import("./../../../src/pages/integration-connectors.tsx" /* webpackChunkName: "component---src-pages-integration-connectors-tsx" */),
  "component---src-pages-knowledge-base-tsx": () => import("./../../../src/pages/knowledge-base.tsx" /* webpackChunkName: "component---src-pages-knowledge-base-tsx" */),
  "component---src-pages-okta-partnership-tsx": () => import("./../../../src/pages/okta-partnership.tsx" /* webpackChunkName: "component---src-pages-okta-partnership-tsx" */),
  "component---src-pages-product-engineering-tsx": () => import("./../../../src/pages/product-engineering.tsx" /* webpackChunkName: "component---src-pages-product-engineering-tsx" */),
  "component---src-pages-request-demo-tsx": () => import("./../../../src/pages/request-demo.tsx" /* webpackChunkName: "component---src-pages-request-demo-tsx" */),
  "component---src-pages-services-identity-tsx": () => import("./../../../src/pages/services-identity.tsx" /* webpackChunkName: "component---src-pages-services-identity-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-blow-away-your-styles-with-tailwind-css-tailwind-css-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/blow-away-your-styles-with-tailwind-css/tailwindCSS.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-blow-away-your-styles-with-tailwind-css-tailwind-css-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-grpc-microservices-api-invocation-framework-grpc-blog-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/grpc-microservices-api-invocation-framework/grpc_blog.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-grpc-microservices-api-invocation-framework-grpc-blog-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-introduction-to-xarray-xarray-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/introduction-to-xarray/xarray.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-introduction-to-xarray-xarray-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-kafka-chronicles-kafka-chronicles-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/kafka-chronicles/kafka-chronicles.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-kafka-chronicles-kafka-chronicles-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-mapboxgl-wind-plot-mapboxgl-wind-plot-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/mapboxgl-wind-plot/mapboxgl-wind-plot.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-mapboxgl-wind-plot-mapboxgl-wind-plot-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-mongodb-geo-indexing-mongodb-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/mongodb-geo-indexing/mongodb.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-mongodb-geo-indexing-mongodb-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-react-custom-hook-typescript-to-download-a-file-through-api-download-hook-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/react-custom-hook-typescript-to-download-a-file-through-api/downloadHook.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-react-custom-hook-typescript-to-download-a-file-through-api-download-hook-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-reusable-presentational-components-in-angular-6-reusable-angular-components-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/reusable-presentational-components-in-angular-6/reusableAngularComponents.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-reusable-presentational-components-in-angular-6-reusable-angular-components-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-scaling-data-platform-scaling-data-platform-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/scaling-data-platform/scaling-data-platform.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-scaling-data-platform-scaling-data-platform-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-timescaledb-timescaledb-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/timescaledb/timescaledb.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-timescaledb-timescaledb-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-what-is-devops-what-is-devops-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/what-is-devops/what-is-devops.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-what-is-devops-what-is-devops-mdx" */),
  "component---src-templates-blog-template-tsx-content-file-path-src-blogs-what-is-web-accessibility-what-is-web-accessibility-mdx": () => import("./../../../src/templates/blogTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/blogs/what-is-web-accessibility/what-is-web-accessibility.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-src-blogs-what-is-web-accessibility-what-is-web-accessibility-mdx" */),
  "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-amplify-podcast-reach-with-real-time-stream-processing-and-analytics-amplify-podcast-reach-with-real-time-stream-processing-and-analytics-mdx": () => import("./../../../src/templates/caseStudyTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/case-studies/amplify-podcast-reach-with-real-time-stream-processing-and-analytics/amplify-podcast-reach-with-real-time-stream-processing-and-analytics.mdx" /* webpackChunkName: "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-amplify-podcast-reach-with-real-time-stream-processing-and-analytics-amplify-podcast-reach-with-real-time-stream-processing-and-analytics-mdx" */),
  "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-api-integration-and-data-ingestion-platform-copy-9-api-integration-and-data-ingestion-platform-mdx": () => import("./../../../src/templates/caseStudyTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/case-studies/api-integration-and-data-ingestion-platform copy 9/api-integration-and-data-ingestion-platform.mdx" /* webpackChunkName: "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-api-integration-and-data-ingestion-platform-copy-9-api-integration-and-data-ingestion-platform-mdx" */),
  "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-auth-0-iam-implementation-for-zero-trust-networking-platform-auth-0-iam-implementation-mdx": () => import("./../../../src/templates/caseStudyTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/case-studies/auth0-iam-implementation-for-zero-trust-networking-platform/auth0_iam_implementation.mdx" /* webpackChunkName: "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-auth-0-iam-implementation-for-zero-trust-networking-platform-auth-0-iam-implementation-mdx" */),
  "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-business-innovation-in-equipment-maintenance-maintenance-manager-casestudy-mdx": () => import("./../../../src/templates/caseStudyTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/case-studies/business-innovation-in-equipment-maintenance/maintenance_manager_casestudy.mdx" /* webpackChunkName: "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-business-innovation-in-equipment-maintenance-maintenance-manager-casestudy-mdx" */),
  "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-data-orchestration-ecosystem-data-orchestration-ecosystem-mdx": () => import("./../../../src/templates/caseStudyTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/case-studies/data-orchestration-ecosystem/data-orchestration-ecosystem.mdx" /* webpackChunkName: "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-data-orchestration-ecosystem-data-orchestration-ecosystem-mdx" */),
  "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-deep-dive-into-oceanographic-data-deep-dive-into-oceanographic-data-mdx": () => import("./../../../src/templates/caseStudyTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/case-studies/deep-dive-into-oceanographic-data/deep-dive-into-oceanographic-data.mdx" /* webpackChunkName: "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-deep-dive-into-oceanographic-data-deep-dive-into-oceanographic-data-mdx" */),
  "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-empowering-specially-abled-healthcare-professionals-empowering-specially-abled-healthcare-professionals-mdx": () => import("./../../../src/templates/caseStudyTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/case-studies/empowering-specially-abled-healthcare-professionals/empowering-specially-abled-healthcare-professionals.mdx" /* webpackChunkName: "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-empowering-specially-abled-healthcare-professionals-empowering-specially-abled-healthcare-professionals-mdx" */),
  "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-okta-iam-to-azure-ad-migration-okta-iam-to-azure-ad-migration-mdx": () => import("./../../../src/templates/caseStudyTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/case-studies/okta-iam-to-azure-ad-migration/okta-iam-to-azure-ad-migration.mdx" /* webpackChunkName: "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-okta-iam-to-azure-ad-migration-okta-iam-to-azure-ad-migration-mdx" */),
  "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-role-based-access-control-role-based-accees-control-mdx": () => import("./../../../src/templates/caseStudyTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/case-studies/role-based-access-control/role_based_accees_control.mdx" /* webpackChunkName: "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-role-based-access-control-role-based-accees-control-mdx" */),
  "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-sample-casestudy-sample-casestudy-mdx": () => import("./../../../src/templates/caseStudyTemplate.tsx?__contentFilePath=/codebuild/output/src576710862/src/src/case-studies/sample_casestudy/sample_casestudy.mdx" /* webpackChunkName: "component---src-templates-case-study-template-tsx-content-file-path-src-case-studies-sample-casestudy-sample-casestudy-mdx" */),
  "component---src-templates-job-detail-template-tsx": () => import("./../../../src/templates/jobDetailTemplate.tsx" /* webpackChunkName: "component---src-templates-job-detail-template-tsx" */)
}

